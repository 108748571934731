<style scoped>
.menu_text_main {
    font: normal normal 600 18px/25px Apple SD Gothic Neo !important;
    color: #333333;
}

.menu_text_sub {
    font: normal normal normal 16px/25px Apple SD Gothic Neo !important;
    color: #333333;
}
</style>
<template>
    <v-sheet style="background-color:#F4F6F8;">
        <v-list>
            <template v-for="(menuItem, menuIdx) in menus">
                <v-list-item class="mb-3 pl-5" v-if="menuItem.type == 'GROUP_TITLE'" :key="menuIdx">
                    <v-list-item-content>
                        <v-list-item-title style="color:#999999">NAVIGATION</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>

                <v-list-item class="pl-5" link v-if="menuItem.type == 'LINK'" :key="menuIdx" :to="menuItem.to"
                             style="height:60px;">
                    <v-list-item-icon class="pr-0 mr-5">
                        <v-icon color="#5079B5">{{ menuItem.icon }}</v-icon>
                    </v-list-item-icon>

                    <v-list-item-content>
                        <v-list-item-title class="menu_text_main">{{ menuItem.title }}</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>

                <v-list-group
                    :value="false"
                    v-if="menuItem.type == 'GROUP'" :key="menuIdx"
                    color="black"
                    class="main_menu_item "
                >
                    <template v-slot:activator>
                        <v-list-item-icon class="pl-1 pr-0 mr-5" style="height:35px;">
                            <v-icon color="#5079B5">{{ menuItem.icon }}</v-icon>
                        </v-list-item-icon>
                        <v-list-item-title class="menu_text_main">{{ menuItem.title }}</v-list-item-title>
                    </template>

                    <v-list-item link v-for="(subMenuItem, subMenuIdx) in menuItem.sub_menus"
                                 :key="menuIdx + ':' + subMenuIdx" :to="subMenuItem.to"
                                 style="background-color:#ffffff;" class="pl-1">
                        <v-list-item-icon></v-list-item-icon>
                        <v-list-item-content>
                            <v-list-item-title class="menu_text_sub">{{ subMenuItem.title }}</v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                </v-list-group>

            </template>

        </v-list>

    </v-sheet>
</template>

<script>

export default {
    name: 'left_area',

    components: {},

    data: () => ({
        menus: [
            {type: 'GROUP_TITLE', title: 'NAVIGATION'},
            {type: 'LINK', icon: 'mdi-home-outline', title: '홈', to: '/dashboard'},
            {type: 'LINK', icon: 'mdi-face-agent', title: '콜리 설정', to: '/svc/staff/list'},
            {type: 'LINK', icon: 'mdi-clock-edit', title: '인사말&영업시간', to: '/svc/voice_intro'},
            {type: 'LINK', icon: 'mdi-phone-log', title: 'ARS 메뉴', to: '/svc/voice_scn'},
            {type: 'LINK', icon: 'mdi-card-account-phone-outline', title: '보이는 ARS', to: '/svc/wv'},
            {type: 'LINK', icon: 'mdi-phone-off', title: '수신 차단 목록', to: '/svc/block_mdn'},
            {type: 'LINK', icon: 'mdi-newspaper-variant-multiple-outline', title: '주소록', to: '/addr_book/list'},
            {
                type: 'GROUP', icon: 'mdi-view-list', title: '서비스 이력', to: '/stats/graph', open: false,
                sub_menus: [
                    {type: 'LINK', title: '수신통화 이력', to: '/svc_hist/svc_list'},
                    {type: 'LINK', title: '발신통화 이력', to: '/svc_hist/send_call_list'},
                    {type: 'LINK', title: '콜백처리 이력', to: '/svc_hist/callback_list'},
                    {type: 'LINK', title: '메모', to: '/svc_hist/cust_memo_group_list'},
                ]
            },
            {
                type: 'GROUP', icon: 'mdi-chart-pie', title: '통계', to: '/stats/graph', open: false,
                sub_menus: [
                    {type: 'LINK', title: 'ARS메뉴별 통계', to: '/stats/menu_list'},
                    {type: 'LINK', title: '콜리별 통계', to: '/stats/user_list'},
                    {type: 'LINK', title: '수신통화 통계', to: '/stats/svc_call_list'},
                ]
            },
            {type: 'LINK', icon: 'mdi-currency-usd', title: '결제 정보', to: '/pay'},
            {type: 'LINK', icon: 'mdi-account-key', title: '관리자 정보', to: '/admin/info'},
            //{type: 'LINK', icon: 'mdi-help-circle-outline', title: '도움말', to: '/help'}
        ],
    }),
};
</script>
<style scoped>

</style>
